<template>
  <div>
    <section class="mainBlock">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] pt-[50px]">
            <div class="col-span-7 text-white z-40 lg:mt-0 mt-[20px]">
              <h1 class="lg:text-[52px] text-[42px] font-bold">
                {{ $t("driverPage.findJob.text1") }}
              </h1>
              <p class="text-[20px] mt-[30px] lg:block hidden">
                {{ $t("driverPage.findJob.text2") }}
              </p>
              <div class="flex">
                <div class="flex lg:mt-[30px] mt-[150px]">
                  <img src="@/assets/img/icon/coin.svg" alt="" />
                  <div class="text-[20px] ml-[10px]">
                    <p>{{ $t("driverPage.findJob.text3") }}</p>
                    <p>{{ $t("driverPage.findJob.text4") }}</p>
                  </div>
                </div>
                <img
                  @click="openInfoModal()"
                  class="mt-[-20px] cursor-pointer"
                  src="@/assets/img/icon/info.svg"
                  alt=""
                />
              </div>
              <div class="mt-[30px]">
                <a
                  target="_blank"
                  href="https://traffic.gram.tj/app/register/1/"
                >
                  <button
                    class="no-underline border-none rounded-[10px] leading-[20px] font-medium hover:opacity-80 cursor-pointer duration-200 px-[25px] py-[14px] bg-white text-black"
                  >
                    {{ $t("driverPage.buttonText.text") }}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <h2
            class="lg:text-[36px] md:text-[32px] col-span-11 md:leading-[40px] text-[28px] leading-[36px] mb-[66px] lg:leading-[44px] font-bold"
          >
            {{ $t("driverPage.driverText.text") }}
          </h2>
          <div
            class="lg:grid lg:grid-cols-12 lg:gap-[36px] md:grid md:grid-cols-12 md:gap-[36px]"
          >
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img
                class="mb-[20px] w-[50px]"
                src="@/assets/img/icon/carbon_flash.svg"
                alt=""
              />
              <div>
                <h4 class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  {{ $t("driverPage.driverText.text1") }}
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p>
                  {{ $t("driverPage.driverText.text2") }}
                </p>
              </div>
            </div>
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img
                class="mb-[20px] w-[50px]"
                src="@/assets/img/icon/mdi_clock-outline.svg"
                alt=""
              />
              <div>
                <h4 class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  {{ $t("driverPage.driverText.text3") }}
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p>
                  {{ $t("driverPage.driverText.text4") }}
                </p>
              </div>
            </div>
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img
                class="mb-[20px] w-[50px]"
                src="@/assets/img/icon/person.svg"
                alt=""
              />
              <div>
                <h4 class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  {{ $t("driverPage.driverText.text5") }}
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p>
                  {{ $t("driverPage.driverText.text6") }}
                </p>
              </div>
            </div>
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img
                class="mb-[20px] w-[50px]"
                src="@/assets/img/icon/majesticons_coins-line.svg"
                alt=""
              />
              <div>
                <h4 class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  {{ $t("driverPage.driverText.text7") }}
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p>
                  {{ $t("driverPage.driverText.text8") }}
                </p>
              </div>
            </div>
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img
                class="mb-[20px] w-[50px]"
                src="@/assets/img/icon/procent.svg"
                alt=""
              />
              <div>
                <h4 class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  {{ $t("driverPage.driverText.text9") }}
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p>
                  {{ $t("driverPage.driverText.text10") }}
                </p>
              </div>
            </div>
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img
                class="mb-[20px] w-[50px]"
                src="@/assets/img/Frame2050.svg"
                alt=""
              />
              <div>
                <h4 class="text-[20px] font-medium leading-[24px] mb-[12px]">
                  {{ $t("driverPage.driverText.text11") }}
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p>
                  {{ $t("driverPage.driverText.text12") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="col-span-6 lg:mr-auto lg:mt-0 mt-[30px]">
              <img class="" src="@/assets/img/app2.jpg" alt="" />
            </div>
            <div class="col-span-5 lg:mt-0 mt-[20px]">
              <h2 class="lg:text-4xl text-2xl font-bold lg:mb-[40px]">
                {{ $t("driverPage.downApp.text1") }}
              </h2>
              <p class="lg:w-[425px] pt-[26px]">
                {{ $t("driverPage.downApp.text2") }}
              </p>
              <div class="flex flex-wrap">
                <router-link target="_blank" to="/drever/app">
                  <div
                    class="no-underline border-none rounded-[10px] leading-[20px] font-medium hover:opacity-80 mt-[12px] cursor-pointer duration-200 px-[50px] py-[14px] bg-black text-white"
                  >
                    {{ $t("driverPage.buttonText.text2") }}
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-[#f6f6f6]">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div
            class="bg-white lg:grid lg:grid-cols-12 lg:gap-[36px] p-[20px] rounded-[10px] shadow-lg"
          >
            <div
              class="col-span-7 lg:border-r-[2px] lg:border-b-0 border-b-[2px] pb-[36px] lg:pr-[36px]"
            >
              <h2
                class="md:text-[50px] font-semibold text-[42px] leading-[42px] mb-[30px]"
              >
                {{ $t("driverPage.calculate.text1") }}
              </h2>
              <div>
                <h4 class="mb-[12px]">
                  {{ $t("driverPage.calculate.text2") }}
                </h4>
                <div
                  class="flex bg-[#efefef] rounded-[10px] w-full p-[2px] sm:h-[60px] h-[44px] mb-[30px]"
                >
                  <button
                    @click="activeFirstButton()"
                    :class="`w-full ${
                      tariffButton == 1 ? 'bg-white shadow-md' : 'bg-[#efefef]'
                    } rounded-[10px]`"
                  >
                    Электро ⚡
                  </button>
                  <button
                    @click="activeSecondButton()"
                    :class="`w-full ${
                      tariffButton == 2 ? 'bg-white shadow-md' : 'bg-[#efefef]'
                    } mx-[6px] rounded-[10px]`"
                  >
                    Комфорт
                  </button>
                  <button
                    @click="activeThirdButton()"
                    :class="`w-full ${
                      tariffButton == 3 ? 'bg-white shadow-md' : 'bg-[#efefef]'
                    } rounded-[10px]`"
                  >
                    Ultra
                  </button>
                </div>
              </div>
              <!-- <div v-if="tariffButton == 3">
                  <h4 class="mb-[12px]">Как будете доставлять?</h4>
                  <div class="flex bg-[#efefef] rounded-[10px] w-full p-[2px] sm:h-[60px] h-[44px] mb-[20px]">
                    <button @click="delivery1()" :class="`w-full ${deliveryButton == 1 ? 'bg-white shadow-md' : 'bg-[#efefef]'} rounded-[10px]`">
                      Пешком
                    </button>
                    <button @click="delivery2()" :class="`w-full ${deliveryButton == 2 ? 'bg-white shadow-md' : 'bg-[#efefef]'} mx-[6px] rounded-[10px]`">
                      <span class="sm:hidden block">
                        На велике 
                      </span>
                      <span class="sm:block hidden">
                        На велосипеде
                      </span>
                    </button>
                    <button @click="delivery3()" :class="`w-full ${deliveryButton == 3 ? 'bg-white shadow-md' : 'bg-[#efefef]'} rounded-[10px]`">
                      На машине
                    </button>
                  </div>
                </div> -->
              <div :class="`${fuelBlock ? 'block' : 'hidden'}`">
                <h4 class="mb-[12px]">
                  {{ $t("driverPage.calculate.text3") }}
                </h4>
                <div
                  class="flex bg-[#efefef] rounded-[10px] w-full p-[2px] sm:h-[60px] h-[44px] mb-[30px]"
                >
                  <button
                    @click="fuel1()"
                    :class="`w-full ${
                      fuelButton == 1 ? 'bg-white shadow-md' : 'bg-[#efefef]'
                    } rounded-[10px]`"
                  >
                    Газ
                  </button>
                  <button
                    @click="fuel2()"
                    :class="`w-full ${
                      fuelButton == 2 ? 'bg-white shadow-md' : 'bg-[#efefef]'
                    } mx-[6px] rounded-[10px]`"
                  >
                    Бензин
                  </button>
                  <button
                    @click="fuel3()"
                    :class="`w-full ${
                      fuelButton == 3 ? 'bg-white shadow-md' : 'bg-[#efefef]'
                    } rounded-[10px] ml-[6px]`"
                  >
                    Дизель
                  </button>
                  <button
                    @click="fuel4()"
                    :class="`w-full ${
                      fuelButton == 4 ? 'bg-white shadow-md' : 'bg-[#efefef]'
                    } rounded-[10px] ml-[6px]`"
                  >
                    Электричество
                  </button>
                  <!-- <button @click="fuel4()" :class="`w-full ${fuelButton == 4 ? 'bg-white shadow-md' : 'bg-[#efefef]'} rounded-[10px]`">
                      Электричество
                    </button> -->
                </div>
                <h4
                  :class="`mb-[12px] ${fuelButton === 4 ? '!hidden' : 'block'}`"
                >
                  {{ $t("driverPage.calculate.text4") }}
                </h4>
                <div
                  :class="`sm:flex mb-[30px] ${
                    fuelButton === 4 ? '!hidden' : 'sm:flex'
                  } hidden`"
                >
                  <div class="bg-black rounded-l-[15px] w-[50px] h-[50px]">
                    <span
                      id="fuelCount"
                      class="text-white ml-[30px] text-[24px] top-[6px] relative"
                      >{{ fuel }}</span
                    >
                  </div>
                  <input
                    id="inputFuel"
                    :max="maxFuel"
                    @input="fuelRange()"
                    :min="minFuel"
                    class="range3 border-none"
                    v-model="fuel"
                    type="range"
                  />
                </div>
                <div
                  :class="`sm:hidden ${
                    fuelButton === 4 ? '!hidden' : 'block'
                  } block mb-[20px]`"
                >
                  <select
                    @change="fuelRange()"
                    v-model="fuel"
                    name=""
                    class="w-full border-none focus:ring-0 rounded-[10px] bg-[#efefef]"
                    id=""
                  >
                    <option
                      v-for="fuel in selectedFuel"
                      :key="fuel"
                      :value="fuel"
                    >
                      {{ fuel }}
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <h4 class="mb-[12px]">
                  {{ $t("driverPage.calculate.text5") }}
                </h4>
                <div class="sm:flex hidden mb-[30px]">
                  <div class="bg-black rounded-l-[15px] w-[50px] h-[50px]">
                    <span
                      id="timeCount"
                      class="text-white ml-[30px] text-[24px] top-[6px] relative"
                      >{{ time }}</span
                    >
                  </div>
                  <input
                    id="inputTime"
                    :max="maxTime"
                    @input="timeRange()"
                    :min="minTime"
                    class="range border-none"
                    v-model="time"
                    type="range"
                  />
                </div>
                <div class="sm:hidden block mb-[20px]">
                  <select
                    @change="timeRange()"
                    v-model="time"
                    name=""
                    class="w-full border-none focus:ring-0 rounded-[10px] bg-[#efefef]"
                    id=""
                  >
                    <option
                      v-for="time in selectedTime"
                      :key="time"
                      :value="time"
                    >
                      {{ time }}
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <h4 class="mb-[12px]">
                  {{ $t("driverPage.calculate.text6") }}
                </h4>
                <div class="sm:flex hidden">
                  <div class="bg-black rounded-l-[15px] w-[50px] h-[50px]">
                    <span
                      id="dateCount"
                      class="text-white ml-[30px] text-[24px] top-[6px] relative"
                      >{{ days }}</span
                    >
                  </div>
                  <input
                    id="inputDay"
                    :max="maxDays"
                    @input="dayRange()"
                    :min="minDays"
                    class="range2 border-none"
                    v-model="days"
                    type="range"
                  />
                </div>
                <div class="sm:hidden block">
                  <select
                    @change="dayRange()"
                    v-model="days"
                    name=""
                    class="w-full border-none focus:ring-0 rounded-[10px] bg-[#efefef]"
                    id=""
                  >
                    <option v-for="day in selectedDays" :key="day" :value="day">
                      {{ day }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-span-5 sm:mt-0 mt-[36px ]">
              <div class="flex flex-col h-full">
                <div class="">
                  <h2
                    class="sm:text-[90px] mt-[10px] md:mt-0 sm:leading-[90px] leading-[60px] text-[50px]"
                  >
                    {{ income + " " + "c." }}
                  </h2>
                  <div class="text-[24px]">
                    {{ $t("driverPage.calculate.text7") }}
                    <span>{{ incomePerHour }}</span>
                    {{ $t("driverPage.calculate.text8") }}
                  </div>
                </div>
                <div class="lg:mt-auto mt-[40px]">
                  <a
                    target="_blank"
                    href="https://traffic.gram.tj/app/register/1/"
                  >
                    <button
                      @mouseover="btnArrow = true"
                      @mouseout="btnArrow = false"
                      class="no-underline border-none rounded-[10px] flex items-center leading-[20px] font-medium hover:opacity-80 cursor-pointer duration-200 px-[15px] py-[7px] md:px-[25px] md:py-[14px] bg-black text-white"
                    >
                      {{ $t("driverPage.buttonText.text3") }}
                      <img
                        :class="`mt-[5px] w-[30px] relative ${
                          btnArrow ? 'right-[-20px]' : 'right-[-10px]'
                        } duration-200`"
                        src="@/assets/img/icons8-направо-50.png"
                        alt=""
                      />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div>
            <h2
              class="lg:text-[36px] md:text-[32px] col-span-11 md:leading-[40px] text-[28px] leading-[36px] mb-[36px] lg:leading-[44px] font-bold"
            >
              {{ $t("driverPage.workTree.text1") }}
            </h2>
            <div class="relative top-0">
              <div class="workBlock pb-[40px]">
                <div class="pl-[30px] block">
                  <h4 class="text-[18px] mb-[12px] font-medium leading-[24px]">
                    {{ $t("driverPage.workTree.text2") }}
                  </h4>
                  <div>
                    <p class="font-normal text-[16px]">
                      {{ $t("driverPage.workTree.text10") }}
                      <a
                        target="_blank"
                        class="font-bold hover:underline"
                        href="https://traff.gram.tj/app/register/1"
                        >{{ $t("driverPage.workTree.text9") }}</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative top-0">
              <div class="workBlock pb-[40px]">
                <div class="pl-[30px] block">
                  <h4 class="text-[18px] mb-[12px] font-medium leading-[24px]">
                    CMC
                  </h4>
                  <div>
                    <p class="font-normal text-[16px]">
                      {{ $t("driverPage.workTree.text4") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative top-0">
              <div class="workBlock pb-[40px]">
                <div class="pl-[30px] block">
                  <h4 class="text-[18px] mb-[12px] font-medium leading-[24px]">
                    {{ $t("driverPage.workTree.text5") }}
                  </h4>
                  <div>
                    <p class="font-normal text-[16px]">
                      {{ $t("driverPage.workTree.text6") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative top-0">
              <div class="workEnd pb-[40px]">
                <div class="pl-[30px] block">
                  <h4 class="text-[18px] mb-[12px] font-medium leading-[24px]">
                    {{ $t("driverPage.workTree.text7") }}
                  </h4>
                  <div>
                    <p class="font-normal text-[16px]">
                      {{ $t("driverPage.workTree.text8") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <a target="_blank" href="https://traffic.gram.tj/app/register/1/">
              <button
                class="no-underline border-none rounded-[10px] leading-[20px] font-medium hover:opacity-80 cursor-pointer duration-200 px-[25px] py-[14px] bg-black text-white"
              >
                {{ $t("driverPage.buttonText.text") }}
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-[#f6f6f6]">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <h2
            class="lg:text-[36px] md:text-[32px] md:leading-[40px] leading-[36px] text-[28px] lg:leading-[44px] font-bold mb-[12px]"
          >
            {{ $t("driverPage.optimize.text1") }}
          </h2>
          <h4
            class="lg:text-[20px] md:text-[20px] md:leading-[18px] leading-[20px] text-[18px] lg:leading-[20px] mb-[36px]"
          >
            {{ $t("driverPage.optimize.text2") }}
          </h4>
          <div
            class="lg:grid lg:grid-cols-12 lg:gap-[36px] flex flex-col md:gap-[36px]"
          >
            <div
              @mouseover="apphover1 = true"
              @mouseout="apphover1 = false"
              class="w-full relative cursor-pointer lg:col-span-6 mb-[36px] bg-white lg:mb-0"
            >
              <router-link
                target="_blank"
                to="/driver/app"
                class="flex items-center w-full"
              >
                <div class="lg:my-[24px] lg:ml-[24px] my-[16px] ml-[16px]">
                  <img
                    class="w-[150px]"
                    src="@/assets/img/trafficGram.png"
                    alt=""
                  />
                </div>
                <div
                  class="lg:py-[24px] py-[16px] pl-[16px] lg:pl-[24px] pr-[48px]"
                >
                  <h3
                    class="lg:text-[20px] text-[20px] font-bold leading-[32px]"
                  >
                    {{ $t("driverPage.optimize.text3") }}
                  </h3>
                </div>
                <div
                  :class="`absolute duration-300 ${
                    apphover1 ? 'right-[10px]' : 'right-[24px]'
                  }`"
                >
                  <img src="@/assets/img/arrow.svg" class="w-[25px]" alt="" />
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <h2
            class="lg:text-[36px] md:text-[32px] col-span-11 md:leading-[40px] text-[28px] leading-[36px] lg:leading-[44px] font-bold"
          >
            {{ $t("driverPage.questions.text") }}
          </h2>
          <div class="w-full mt-[50px]">
            <div>
              <div
                @click="firstQuestion = !firstQuestion"
                class="flex justify-between items-center pb-[20px] select-none cursor-pointer"
              >
                <h4 class="lg:text-[20px] text-[16px] font-medium">
                  {{ $t("driverPage.questions.text1") }}
                </h4>
                <img
                  :class="`lg:w-[18px] duration-300 ${
                    firstQuestion ? 'rotate-180' : ''
                  }`"
                  src="@/assets/img/V2.svg"
                  alt=""
                />
              </div>
              <hr />
              <div
                :class="`${
                  firstQuestion ? 'lg:h-[100px] h-[170px]' : 'h-0'
                } lg:text-[18px] my-[20px] duration-300 overflow-hidden`"
              >
                {{ $t("driverPage.questions.text2") }}
                <div class="mt-[20px]">
                  <a
                    target="_blank"
                    class="font-medium underline"
                    href="https://traffic.gram.tj/app/register/1/"
                    >{{ $t("driverPage.buttonText.text") }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="w-full mt-[30px]">
            <div>
              <div
                @click="thirdQuestion = !thirdQuestion"
                class="flex h-auto w-full justify-between items-center pb-[20px] select-none cursor-pointer"
              >
                <h4 class="lg:text-[20px] text-[16px] font-medium">
                  {{ $t("driverPage.questions.text3") }}
                </h4>
                <img
                  :class="`lg:w-[18px] duration-300 ${
                    thirdQuestion ? 'rotate-180' : ''
                  }`"
                  src="@/assets/img/V2.svg"
                  alt=""
                />
              </div>
              <hr />
              <div
                :class="`${
                  thirdQuestion ? 'lg:h-[100px] h-[150px]' : 'h-0'
                } my-[20px] duration-300 overflow-hidden`"
              >
                {{ $t("driverPage.questions.text4") }}
                <router-link
                  target="_blank"
                  class="font-medium underline"
                  to="/driver/app"
                  >{{ $t("driverPage.buttonText.text4") }}</router-link
                >
                {{ $t("driverPage.questions.text9") }}
                <div class="mt-[20px]">
                  <router-link
                    target="_blank"
                    class="font-medium underline"
                    to="/driver/app"
                    >{{ $t("driverPage.buttonText.text4") }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="w-full mt-[30px]">
            <div>
              <div
                @click="secondQuestion = !secondQuestion"
                class="flex h-auto w-full justify-between items-center pb-[20px] select-none cursor-pointer"
              >
                <h4 class="lg:text-[20px] mr-[10px] text-[16px] font-medium">
                  {{ $t("driverPage.questions.text5") }}
                </h4>
                <img
                  :class="`lg:w-[18px] duration-300 ${
                    secondQuestion ? 'rotate-180' : ''
                  }`"
                  src="@/assets/img/V2.svg"
                  alt=""
                />
              </div>
              <hr />
              <div
                :class="`${
                  secondQuestion ? 'lg:h-[50px] h-[120px]' : 'h-0'
                } my-[20px] duration-300 overflow-hidden`"
              >
                {{ $t("driverPage.questions.text6") }}
              </div>
            </div>
          </div>
          <div class="w-full mt-[30px]">
            <div>
              <div
                @click="fourthQuestion = !fourthQuestion"
                class="flex h-auto w-full justify-between items-center pb-[20px] select-none cursor-pointer"
              >
                <h4 class="lg:text-[20px] mr-[10px] text-[16px] font-medium">
                  {{ $t("driverPage.questions.text7") }}
                </h4>
                <img
                  :class="`lg:w-[18px] duration-300 ${
                    fourthQuestion ? 'rotate-180' : ''
                  }`"
                  src="@/assets/img/V2.svg"
                  alt=""
                />
              </div>
              <hr />
              <div
                :class="`${
                  fourthQuestion ? 'lg:h-[100px] h-[250px]' : 'h-0'
                } my-[20px] duration-300 overflow-hidden`"
              >
                {{ $t("driverPage.questions.text8") }}
                <div class="mt-[20px]">
                  <router-link
                    target="_blank"
                    class="font-medium underline"
                    to="/driver/app"
                    >{{ $t("driverPage.buttonText.text5") }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div
            class="lg:grid lg:grid-cols-12 lg:gap-[36px] flex flex-col gap-[36px]"
          >
            <div
              @mouseover="registerHover1 = true"
              @mouseout="registerHover1 = false"
              :class="` ${
                registerHover1 ? 'opacity-80' : ''
              } duration-[400ms] lg:col-span-6 cursor-pointer pb-[23px] md:pb-[30px] lg:pb-[20px] border-black border-b-[1px]`"
            >
              <a target="_blank" href="https://traffic.gram.tj/app/register/1/">
                <div class="grid grid-cols-12">
                  <h2
                    class="lg:text-[36px] md:text-[32px] col-span-11 md:leading-[40px] text-[24px] leading-[36px] lg:leading-[44px] font-bold"
                  >
                    {{ $t("driverPage.buttonText.text6") }}
                  </h2>
                  <div class="relative top-[25px]">
                    <div class="flex justify-end absolute overflow-hidden">
                      <div class="flex items-center justify-center float-right">
                        <div
                          :class="`relative ${
                            registerHover1
                              ? 'right-0 duration-500'
                              : 'right-[70px]'
                          }`"
                        >
                          <img
                            src="@/assets/img/arrow.svg"
                            class="w-[48px]"
                            alt=""
                          />
                        </div>
                        <div
                          :class="`absolute ${
                            registerHover1
                              ? 'right-[-70px] duration-500'
                              : 'right-0'
                          }`"
                        >
                          <img
                            src="@/assets/img/arrow.svg"
                            class="w-[48px]"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </section>
    <div
      v-if="showButton"
      style="box-shadow: 0px -5px 12px -7px rgba(34, 60, 80, 0.6)"
      class="fixed bottom-0 w-full bg-white p-[10px]"
    >
      <a target="_blank" href="https://traffic.gram.tj/app/register/1/">
        <button
          class="no-underline border-none rounded-[10px] w-full leading-[20px] font-medium hover:opacity-80 cursor-pointer duration-200 px-[25px] py-[14px] bg-black text-white"
        >
          {{ $t("driverPage.buttonText.text") }}
        </button>
      </a>
    </div>
  </div>
  <div
    v-if="showButton"
    @click="scrollToTop"
    class="fixed bottom-[80px] right-[20px]"
  >
    <button
      style="box-shadow: 0px 1px 31px 4px rgba(34, 60, 80, 0.2)"
      class="border-none p-[15px] bg-white rounded-full"
    >
      <div class="flex">
        <img class="-rotate-90 w-[25px]" src="@/assets/img/arrow.svg" alt="" />
      </div>
    </button>
  </div>
  <info-modal ref="infoModal"></info-modal>
  <!-- <Footer class="mb-[45px]"></Footer> -->
</template>

<script>
import Footer from "@/components/layouts/Footer.vue";
import InfoModal from "@/components/modal/InfoModal.vue";
export default {
  components: {
    Footer,
    InfoModal,
  },
  data() {
    return {
      time: 8,
      firstQuestion: false,
      secondQuestion: false,
      thirdQuestion: false,
      fourthQuestion: false,
      btnArrow: false,
      registerHover1: false,
      minTime: 3,
      apphover1: false,
      maxTime: 12,
      maxDays: 30,
      minDays: 1,
      minFuel: 3,
      maxFuel: 25,
      fuel: 10,
      days: 15,
      tariffButton: 1,
      deliveryButton: 1,
      fuelButton: 1,
      fuelBlock: false,
      showButton: false,
      tariffPrice: 11,
      income: undefined,
      incomePerHour: undefined,
      selectedDays: [],
      selectedTime: [],
      selectedFuel: [],
    };
  },
  mounted() {
    this.isEntered();
    this.fuelRange();
    window.scrollTo({ top: 0 });
    this.timeRange();
    this.dayRange();
    this.calculate();
    window.addEventListener("scroll", this.handleScroll);
    for (let i = 1; i <= 30; i++) {
      this.selectedDays.push(i);
    }
    for (let i = 3; i <= 12; i++) {
      this.selectedTime.push(i);
    }
    for (let i = 3; i <= 25; i++) {
      this.selectedFuel.push(i);
    }
  },
  methods: {
    openInfoModal() {
      this.$refs.infoModal.openModal();
    },
    handleScroll() {
      this.showButton = window.scrollY > 570;
    },
    isEntered() {
      this.$axios.post(
        "https://api-3taxi.gram.tj/api/qr/scan?api_key=key_sXP4x1XkRzFBAsIoP1bhN4HTBsRRTfxN_qr_scan_api"
      );
    },
    calculate() {
      const orders = 3;
      const gas_price = 7.4;
      const petrol_price = 11;
      const diesel_price = 12;
      const order_distance = 4;
      const order_commission = 10;
      let hourSum = this.time * this.days;
      let orders_sum = orders * hourSum;
      let distance_sum = orders_sum * order_distance;
      if (this.tariffButton == 1) {
        this.tariffPrice = 12;
        this.fuelButton = 4;
      } else if (this.tariffButton == 2) {
        this.tariffPrice = 15;
      } else if (this.tariffButton == 3) {
        this.tariffPrice = 60;
      }
      let order_price_sum = orders_sum * this.tariffPrice;
      let fuel_price_sum;
      let commission = (order_price_sum / 100) * order_commission;
      if (this.fuelButton == 1) {
        fuel_price_sum = (distance_sum / 100) * this.fuel * gas_price;
      } else if (this.fuelButton == 2) {
        fuel_price_sum = (distance_sum / 100) * this.fuel * petrol_price;
      } else if (this.fuelButton == 3) {
        fuel_price_sum = (distance_sum / 100) * this.fuel * diesel_price;
      }
      if (this.fuelBlock && this.fuelButton !== 4) {
        this.income = order_price_sum - fuel_price_sum - commission;
      } else {
        this.income = order_price_sum - commission;
      }
      this.incomePerHour = this.income / hourSum;
      this.incomePerHour = Math.round(this.incomePerHour);
      this.income = Math.round(this.income);
      this.income = this.income
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    timeRange() {
      var value = ((this.time - this.minTime) / 9.2) * 100;
      let a = document.getElementById("inputTime");
      let b = document.getElementById("timeCount");
      b.style.left = value * 5 + "%";
      a.style.background =
        "linear-gradient(to right, #000 0%,  " +
        value +
        "%, #efefef " +
        value +
        "%, #efefef 100%)";
      this.calculate();
    },
    dayRange() {
      var value = ((this.days - this.minDays) / 30) * 100;
      let a = document.getElementById("inputDay");
      let b = document.getElementById("dateCount");
      b.style.left = value * 5 + "%";
      a.style.background =
        "linear-gradient(to right, #000 0%,  " +
        value +
        "%, #efefef " +
        value +
        "%, #efefef 100%)";
      this.calculate();
    },
    fuelRange() {
      var value = ((this.fuel - this.minFuel) / 22.3) * 100;
      let a = document.getElementById("inputFuel");
      let b = document.getElementById("fuelCount");
      b.style.left = value * 5 + "%";
      a.style.background =
        "linear-gradient(to right, #000 0%,  " +
        value +
        "%, #efefef " +
        value +
        "%, #efefef 100%)";
      this.calculate();
    },
    activeFirstButton() {
      this.fuelRange();
      this.tariffButton = 1;
      this.fuelBlock = false;
      this.calculate();
    },
    activeSecondButton() {
      this.fuelRange();
      this.tariffButton = 2;
      this.fuelBlock = true;
      this.calculate();
    },
    activeThirdButton() {
      this.fuelRange();
      this.tariffButton = 3;
      this.fuelBlock = true;
      this.calculate();
    },
    fuel1() {
      this.fuelButton = 1;
      this.calculate();
    },
    fuel2() {
      this.fuelButton = 2;
      this.calculate();
    },
    fuel3() {
      this.fuelButton = 3;
      this.calculate();
    },
    fuel4() {
      this.fuelButton = 4;
      this.calculate();
    },
    // delivery1(){
    //   this.deliveryButton = 1
    //   this.fuelBlock = false
    //   this.calculate()
    // },
    // delivery2(){
    //   this.deliveryButton = 2
    //   this.fuelBlock = false
    //   this.calculate()
    // },
    // delivery3(){
    //   this.deliveryButton = 3
    //   this.fuelBlock = true
    //   this.calculate()
    // }
  },
};
</script>

<style scoped>
.range {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0 15px 15px 0;
  border: none;
  width: 100%;
  background: linear-gradient(
    to right,
    #000 0%,
    #000 18%,
    #efefef 18%,
    #efefef 100%
  );
}
.range2 {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0 15px 15px 0;
  border: none;
  width: 100%;
  background: linear-gradient(
    to right,
    #000 0%,
    #000 10%,
    #efefef 48%,
    #efefef 100%
  );
}
.range3 {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0 15px 15px 0;
  border: none;
  width: 100%;
  background: linear-gradient(
    to right,
    #000 0%,
    #000 33%,
    #efefef 33%,
    #efefef 100%
  );
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 30px;
  border-radius: 0 15px 15px 0;
  height: 50px;
  background-color: black;
  background-image: url(@/assets/img/icon/Group2.svg);
  background-repeat: no-repeat;
  background-position: center center;
}
@media screen and (min-width: 1024px) {
  .mainBlock {
    background-image: url(@/assets/img/driver.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height: 701px;
  }
}
@media screen and (max-width: 1024px) {
  .mainBlock {
    background-image: url(@/assets/img/driver-media.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 701px;
  }
}
.workBlock::before {
  content: "";
  width: 9px;
  height: 9px;
  background-color: #000;
  position: absolute;
  display: block;
  top: 8px;
}
.workBlock::after {
  content: "";
  width: 1px;
  background-color: #000;
  position: absolute;
  display: block;
  bottom: 2px;
  top: 24px;
  left: 4px;
}
.workEnd::before {
  content: "";
  width: 9px;
  height: 9px;
  background-color: #000;
  position: absolute;
  display: block;
  top: 8px;
}
</style>
