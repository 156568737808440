<template>
  <div>
    <section class="main-block ">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] pt-[150px]">
            <h1 class="text-[52px] col-span-12 text-white font-bold">{{ $t('blogPage.title1') }}</h1>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px]">
            <div class="lg:col-span-6 col-span-12 lg:mb-0 mb-[36px]">
              <img src="@/assets/img/hamroh.svg" class="lg:w-[300px]" alt="">
            </div>
            <div class="lg:col-span-5 mb-[36px]">
              <h3 class="text-[30px] font-bold">{{ $t('blogPage.title2') }}</h3>
              <p class="mt-[20px] leading-[24px]">
                {{ $t('blogPage.text') }}
              </p>
              <div class="pt-[12px]">
                <div class="absolute font-bold">
                  <a target="_blank" class="cursor-pointer" href="https://hamroh.com/" @mouseover="hover = true" @mouseout="hover = false">
                    {{ $t('blogPage.button') }}
                    <div class="w-full h-[1px] bg-[#cbcbcb] relative top-[1px]"></div>
                    <div :class="`${hover ? 'w-full' : 'w-0'} h-[1px] rounded-sm duration-300 bg-black relative`"></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- <Footer></Footer> -->
</template>

<script>
import Footer from "@/components/layouts/Footer.vue"
export default {
  components: {
    Footer
  },
  data(){
    return {
      hover: false
    }
  },
  mounted(){
    window.scrollTo({ top: 0 })
  },
}
</script>

<style scoped>
.main-block {
  background-image: url(@/assets/img/blog.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100%;
  height: auto;
}
</style>