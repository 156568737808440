<template>
  <div class="bg-black bottom-0 text-white select-none">
    <div class="lg:max-w-7xl w-full ml-auto mr-auto px-[34px] pt-[30px]">
      <div>
        <div class="cursor-pointer" @click="toHome">
          <img class="w-[100px]" src="../../assets/img/Gram.svg" alt="" />
        </div>
      </div>
      <div class="mt-[60px] lg:flex lg:justify-between grid gap-[36px]">
        <div class="">
          <h2 class="text-[18px] font-medium">
            {{ $t("footer.companyText.company") }}
          </h2>
          <div class="flex flex-col">
            <router-link
              :to="`/${country}/${city}/about`"
              class="pt-5 hover:text-gray-300 cursor-pointer"
              >{{ $t("footer.companyText.about") }}</router-link
            >
            <!-- <router-link to="/apps" class="pt-4 hover:text-gray-300 cursor-pointer">Услуги</router-link> -->
            <router-link
              :to="`/${country}/${city}/news`"
              class="pt-4 hover:text-gray-300 cursor-pointer"
              >{{ $t("footer.companyText.blog") }}</router-link
            >
            <a
              target="_blank"
              href="http://job.gram.tj/index.html"
              class="pt-4 hover:text-gray-300 cursor-pointer"
              >{{ $t("footer.companyText.career") }}</a
            >
            <router-link
              :to="`/${country}/${city}/contacts`"
              class="pt-4 hover:text-gray-300 cursor-pointer"
              >{{ $t("footer.companyText.contacts") }}</router-link
            >
          </div>
        </div>
        <div class="">
          <h2 class="text-[18px] font-medium">
            {{ $t("footer.companyText.projects") }}
          </h2>
          <div class="flex flex-col">
            <router-link
              :to="`/${country}/${city}/ride`"
              class="pt-5 hover:text-gray-300 cursor-pointer"
              >{{ $t("footer.companyText.rides") }}</router-link
            >
            <router-link
              :to="`/${country}/${city}/driver`"
              class="pt-4 hover:text-gray-300 cursor-pointer"
              >{{ $t("footer.companyText.driver") }}</router-link
            >
            <a
              target="_blank"
              href="https://hamroh.com/"
              class="pt-4 hover:text-gray-300 cursor-pointer"
              >{{ $t("footer.companyText.hamroh") }}</a
            >
          </div>
        </div>
        <div class="flex flex-col justify-around">
          <div class="flex flex-col">
            <a
              target="_blank"
              class="border-white mb-[10px] border-[1px]"
              :href="
                url === 'driver'
                  ? 'https://play.google.com/store/apps/details?id=com.iogram.traffic'
                  : 'https://play.google.com/store/apps/details?id=com.gram.client'
              "
            >
              <img
                class="mr-[10px]"
                src="../../assets/img/images/googleplay.svg"
                alt=""
              />
            </a>
            <a
              target="_blank"
              class="border-white border-[1px]"
              :href="
                url === 'driver'
                  ? 'https://apps.apple.com/tj/app/traffic-gram/id6670337047'
                  : 'https://apps.apple.com/us/app/gram/id6474066562'
              "
            >
              <img
                class="mr-[10px]"
                src="../../assets/img/images/appstore.svg"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
      <div class="mt-[20px] sm:flex justify-between">
        <div class="flex justify-between md:justify-start">
          <a
            target="_blank"
            href="https://www.facebook.com/gramtj?mibextid=ZbWKwL"
          >
            <img
              class="border border-black bg-black p-4 rounded-lg hover:bg-[#333333] duration-300 cursor-pointer"
              src="@/assets/img/icon/facebook.svg"
              alt="1"
            />
          </a>
          <a target="_blank" href="https://tj.linkedin.com/company/gramtj">
            <img
              class="border border-black bg-black p-4 rounded-lg hover:bg-[#333333] duration-300 cursor-pointer lg:ml-14"
              src="@/assets/img/icon/in.svg"
              alt="1"
            />
          </a>
          <a
            target="_blank"
            href="https://instagram.com/gram.tj?igshid=MDM4ZDc5MmU="
          >
            <img
              class="border border-black bg-black p-4 rounded-lg hover:bg-[#333333] duration-300 cursor-pointer lg:ml-14"
              src="@/assets/img/icon/Group.svg"
              alt="1"
            />
          </a>
        </div>
        <div class="flex">
          <button
            @click="changeLanguage"
            class="p-[10px] text-white rounded-[8px] hover:bg-[#333333] duration-300 font-[500] flex items-center"
          >
            <div class="mr-[8px]">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                <title>Globe</title>
                <path
                  d="M12 1C5.9 1 1 5.9 1 12s4.9 11 11 11 11-4.9 11-11S18.1 1 12 1Zm8 11c0 .7-.1 1.4-.3 2-.6-1.5-1.6-3.1-3-4.7l1.8-1.8c1 1.3 1.5 2.8 1.5 4.5ZM6.5 6.5c1.3 0 3.6.8 6 2.9l-3.2 3.2C7.1 9.8 6.5 7.5 6.5 6.5Zm8.1 5c2.3 2.7 2.9 5 2.9 6-1.3 0-3.6-.8-6-2.9l3.1-3.1Zm1.9-6.1-1.9 1.9c-1.6-1.4-3.2-2.4-4.7-3 .7-.2 1.3-.3 2-.3 1.8 0 3.3.5 4.6 1.4ZM4 12c0-.7.1-1.4.3-2 .6 1.5 1.6 3.1 3 4.7l-1.8 1.8C4.5 15.2 4 13.7 4 12Zm3.5 6.6 1.9-1.9c1.6 1.4 3.2 2.4 4.7 3-.7.2-1.3.3-2 .3-1.8 0-3.3-.5-4.6-1.4Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <span>{{ $t("footer.companyText.switchBtn") }}</span>
          </button>
          <button
            @click="openLocationPage = 1"
            class="p-[10px] text-white rounded-[8px] hover:bg-[#333333] duration-300 font-[500] flex items-center"
          >
            <div class="mr-[8px]">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                <title>Location marker</title>
                <path
                  d="M18.7 3.8C15 .1 9 .1 5.3 3.8c-3.7 3.7-3.7 9.8 0 13.5L12 24l6.7-6.8c3.7-3.6 3.7-9.7 0-13.4ZM12 12.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            {{ showCity }}
          </button>
        </div>
      </div>
      <div class="mt-[20px] flex items-center">
        <p class="text-[14px]">Backed by</p>
        <div class="w-[300px] ml-[10px]">
          <img src="@/assets/img/images/google.svg" alt="" />
        </div>
      </div>
      <div
        class="font-normal lg:flex lg:justify-between lg:flex-row-reverse text-xs text-[#AFAFAF] mt-[50px] pb-[30px] lg:pt-0"
      >
        <div class="lg:block flex flex-col font-normal text-xs text-[#AFAFAF]">
          <router-link
            to="/legal/ru?legal_id=1"
            @click="scrollToTop()"
            class="hover:text-[#3d3a3a] mr-[20px] cursor-pointer"
            >Политика конфиденциальности
          </router-link>
          <router-link
            to="/legal/ru?legal_id=3"
            @click="scrollToTop()"
            class="hover:text-[#3d3a3a] lg:mt-0 mt-[20px] cursor-pointer"
            >Условия использования</router-link
          >
        </div>
        <div>
          <p class="lg:mt-0 mt-[20px]">© 2023 Gram</p>
          <!-- <p class="lg:mt-0 mt-[20px]">Creted by Abduaziz Aminov</p> -->
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-center">
    <div
      :class="`${
        openLocationPage == 1 ? 'h-[360px]' : 'h-0'
      } overflow-hidden rounded-[10px] fixed duration-300 z-[999999] top-[210px] max-w-[1200px] bg-white`"
    >
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="flex justify-end">
            <svg
              @click="close()"
              width="42"
              class="cursor-pointer"
              height="42"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="m18.1 8.1-2.2-2.2-3.9 4-3.9-4-2.2 2.2 4 3.9-4 3.9 2.2 2.2 3.9-4 3.9 4 2.2-2.2-4-3.9 4-3.9Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] mt-[36px]">
            <div class="lg:col-span-4">
              <h3 class="lg:text-[32px] leading-[40px] font-bold">
                Изменить локацию
              </h3>
            </div>
            <div class="lg:col-span-6 relative">
              <input
                id="city"
                @focus="openCityList = true"
                @focusout="unfocusInput()"
                @input="searchCity"
                v-model="changeCity"
                placeholder="Введите свой город"
                class="w-full rounded-2xl focus:ring-0 border-none bg-[#f6f6f6] text-[16px] pr-[33px] py-[11px] pl-[41px] min-h-[52px]"
                type="text"
              />
              <div class="absolute right-[15px] top-[20px]" v-if="changeCity">
                <svg
                  @click="changeCity = null"
                  focusable="false"
                  aria-hidden="true"
                  width="12px"
                  height="12px"
                  fill="#c7bbbb"
                  viewBox="0 0 12 12"
                  role="button"
                  aria-label="Delete pickup location"
                  class="pe-location-clear css-ZSBbY"
                >
                  <path
                    d="M10.595 0L6 4.596 1.405 0 0 1.404 4.596 6 0 10.595 1.405 12 6 7.404 10.595 12 12 10.595 7.404 6 12 1.404z"
                  ></path>
                </svg>
              </div>
              <ul
                v-if="openCityList"
                :class="`absolute bg-[#f6f6f6] z-10 w-full ${
                  cities.length > 5 ? 'h-[220px] overflow-y-scroll' : ''
                } text-ellipsis`"
              >
                <div v-for="city in cities">
                  <li
                    v-if="city.country === country"
                    @click="getCity(city)"
                    :key="city.id"
                    class="text-ellipsis pl-[45px] pr-[30px] py-[10px] overflow-x-hidden hover:bg-[#dddcdc] duration-150 cursor-pointer"
                  >
                    {{ city.name }}
                  </li>
                </div>
                <!-- <li class="text-ellipsis pl-[45px] pr-[30px] py-[10px] overflow-x-hidden hover:bg-[#dddcdc] duration-150 cursor-pointer">Адрес не найден</li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      @click="close()"
      :class="`fixed w-full ${
        openLocationPage == 1 ? 'h-full' : 'h-0'
      } bg-black opacity-50 top-0`"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openLocationPage: 0,
      openCityList: false,
      cities: [
        {
          name: "Dushanbe",
          id: 1,
          value: "dushanbe",
          country: "TJ",
          route: "/TJ/dushanbe",
        },
        {
          name: "Khujand",
          id: 2,
          value: "khujand",
          country: "TJ",
          route: "/TJ/khujand",
        },
        {
          name: "Almaty",
          id: 3,
          value: "almaty",
          country: "KZ",
          route: "/KZ/almaty",
        },
        {
          name: "Astana",
          id: 4,
          value: "astana",
          country: "KZ",
          route: "/KZ/astana",
        },
        {
          name: "Tashkent",
          id: 5,
          value: "tashkent",
          country: "UZ",
          route: "/UZ/tashkent",
        },
      ],
      changeCity: "",
      showCity: "",
      city: "khujand",
      country: "TJ",
      url: null,
    };
  },

  methods: {
    changeLanguage() {
      const currentLanguage = this.$i18n.locale;
      const newLanguage = currentLanguage === "ru" ? "tj" : "ru";
      this.$i18n.locale = newLanguage;
      sessionStorage.setItem("selectedLanguage", newLanguage);
    },
    unfocusInput() {
      setTimeout(() => {
        this.openCityList = false;
      }, 100);
    },
    getCity(city) {
      this.changeCity = city.name;
      this.showCity = this.changeCity;
      this.openCityList = false;
      this.openLocationPage = 0;
      sessionStorage.setItem("openCitySelect", this.openLocationPage);
      this.city = city.value;
      sessionStorage.setItem("city", city.value);
      sessionStorage.setItem("country", city.country);
      this.$router.push(`${city.route}`);
    },
    searchCity() {
      if (this.changeCity) {
        const filteredObjects = this.cities.filter((obj) =>
          obj.name.startsWith(this.changeCity)
        );
        this.cities = filteredObjects;
      } else {
        this.cities = [
          {
            name: "Dushanbe",
            id: 1,
            value: "dushanbe",
            country: "TJ",
            route: "/TJ/dushanbe",
          },
          {
            name: "Khujand",
            id: 2,
            value: "khujand",
            country: "TJ",
            route: "/TJ/khujand",
          },
          {
            name: "Almaty",
            id: 3,
            value: "almaty",
            country: "KZ",
            route: "/KZ/almaty",
          },
          {
            name: "Astana",
            id: 4,
            value: "astana",
            country: "KZ",
            route: "/KZ/astana",
          },
          {
            name: "Tashkent",
            id: 5,
            value: "tashkent",
            country: "UZ",
            route: "/UZ/tashkent",
          },
        ];
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0 });
    },
    toHome() {
      this.$router.push({ path: `/TJ/khujand` });
    },
    close() {
      this.openLocationPage = 0;
      sessionStorage.setItem("openCitySelect", this.openLocationPage);
      this.changeCity = this.showCity;
    },
  },
  watch: {
    $route(to) {
      this.url = to.fullPath.replace(/.*\//, "");
    },
  },
  mounted() {
    const savedLanguage = sessionStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      this.$i18n.locale = savedLanguage;
    }
    this.openLocationPage = sessionStorage.getItem("openCitySelect");
    /* this.$axios
            .get('https://ipinfo.io//json')
            .then(res => {
                this.city = res.data.city.toLowerCase()
                this.showCity = res.data.city
                if (res.data.country != 'TJ') {
                    this.city = 'dushanbe'
                    this.showCity = 'Dushanbe'
                }
                let currentRouteName = this.$route.name.slice(0, this.$route.name.indexOf('_'))
                if (currentRouteName == 'home') {
                    this.toHome()
                }
                sessionStorage.setItem('city', this.city)
            })
            .catch(err => {
            }) */
    sessionStorage.setItem("city", "khujand");
    this.city = sessionStorage.getItem("city");
    this.changeCity = "Khujand";
    this.showCity = "Khujand";
    // if (this.city == 'dushanbe') {
    //     this.changeCity = 'Dushanbe'
    //     this.showCity = 'Dushanbe'
    // }else if(this.city == 'khujand') {
    //     this.changeCity = 'Khujand'
    //     this.showCity = 'Khujand'
    // }else if(this.city == 'tashkent') {
    //     this.changeCity = 'Tashkent'
    //     this.showCity = 'Tashkent'
    // }else if(this.city == 'astana') {
    //     this.changeCity = 'Astana'
    //     this.showCity = 'Astana'
    // }else if (this.city == 'almaty') {
    //     this.changeCity = 'Almaty'
    //     this.showCity = 'Almaty'
    // }
  },
};
</script>

<style scoped></style>
