<template>
  <div>
    <section class="">
      <div class="lg:max-w-7xl w-full ml-auto mr-auto h-full">
        <div class="lg:p-[64px] lg:pt-[94px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="mb-[20px]">
            <h2 class="lg:text-[46px] lg:leading-[54px] md:leading-[40px] leading-[36px] md:text-[40px] text-[38px] font-bold">
              ПЕЙДЖ <br>
              НОТ <br>
              ФАУНД
            </h2>
          </div>
          <div class="mb-[20px] leading-[24px]">
            <p class="text-[18px] font-normal">
              Ошибка 404. Такая страница не существует или находится на стадии разработки.
            </p>
          </div>
          <div class="mt-[12px]">
            <button @click="toHome()" class="border-none leading-[20px] font-medium rounded-[10px] text-[16px] hover:opacity-80 mt-[12px] duration-200 px-[25px] py-[14px] bg-black text-white">
              На главную
            </button>
          </div>
        </div>
      </div>
      <div class="fixed bottom-[-50px] z-[-20] opacity-80 right-0">
        <img src="../assets/img/404.png" alt="">
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    toHome() {
      this.$router.push({name: 'default'})
    }
  }
}
</script>