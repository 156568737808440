<template>
  <div class="absolute h-full w-full bg-white z-[99999999999]">
  </div>
</template>

<script>
export default {
  beforeMount() {
    let id = this.$route.params.id;
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      window.location.href = `mahal://geo?id=${id}`;
    } else if (/iphone|ipad|ipod/i.test(userAgent)) {
      window.location.href = `mahal://geo?id=${id}`;
    } else {
      console.log('Error: Unsupported platform');
    }
  }

}
</script>