<template>
  <div v-if="isOpen" class="absolute z-[99999999999] top-0 left-0 h-full w-full">
    <div @click="closeModal()" class="fixed bg-black bg-opacity-40 top-0 left-0 h-full w-full"></div>
    <div class="flex justify-center items-center h-full">
      <div class="bg-white relative mx-5 rounded-xl p-10 shadow-lg text-lg">
        {{ $t('infoModal.text', { month: localizedMonth, year: currentYear }) }}
        <div class="absolute bg-white rounded-md shadow-xl p-3 top-[-10px] right-[-10px] cursor-pointer" @click="closeModal()">
          <svg focusable="false" aria-hidden="true" width="15px" height="15px" fill="#000" viewBox="0 0 12 12"
            tabindex="0" role="button" aria-label="Delete pickup location">
            <path
              d="M10.595 0L6 4.596 1.405 0 0 1.404 4.596 6 0 10.595 1.405 12 6 7.404 10.595 12 12 10.595 7.404 6 12 1.404z">
            </path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    localizedMonth() {
      const monthIndex = new Date().getMonth();
      const months = {
        ru: [
          'январь', 'февраль', 'март', 'апрель', 'май', 'июнь',
          'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'
        ],
        tj: [
          'январ', 'феврал', 'март', 'апрел', 'май', 'июн',
          'июл', 'август', 'сентябр', 'октябр', 'ноябр', 'декабр'
        ],
      };
      const currentLanguage = this.$i18n.locale;
      return months[currentLanguage]?.[monthIndex] || months['ru'][monthIndex];
    },
  },
  methods: {
    closeModal() {
      this.isOpen = false;
      document.body.style.overflow = '';
    },
    openModal() {
      this.isOpen = true;
      document.body.style.overflow = 'hidden';
    },
  },
};
</script>
