<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.iogram.traffic";
    } else if (
      navigator.userAgent.toLowerCase().indexOf("iphone") > -1 ||
      navigator.userAgent.toLowerCase().indexOf("ipad") > -1
    ) {
      window.location.href =
        "https://apps.apple.com/tj/app/traffic-gram/id6670337047";
    }
  },
};
</script>
