<template>
  <div>
    
  </div>
</template>

<script>
export default {
  mounted() {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.gram.client';
    } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1 || navigator.userAgent.toLowerCase().indexOf("ipad") > -1) {
      window.location.href = 'itms-appss://apps.apple.com/us/app/gram/id6474066562';
    }
  }
}
</script>