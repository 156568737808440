<template>
  <div>
    <div class="lg:p-[64px] lg:max-w-7xl w-full lg:drop-shadow-md mx-[auto] p-[30px]">
      <div>
        <h1 class="lg:text-[46px] md:text-[34px] text-[30px] font-bold">Политика конфиденциальности Gram</h1>
        <p class="text-[16px] leading-[24px] mt-[20px]">Здесь вы можете познакомиться с политикой конфиденциальности и с юридической информацией компании Gram.</p>
      </div>
      <div class="my-[50px] flex flex-col">
        <router-link :to="{ name: 'legalRU', query: {legal_id: 1} }" class="underline lg:text-[26px] md:text-[20px] text-[18px] mb-[40px]">
          Публичная оферта с Клиентом Такси Грам
        </router-link>
        <router-link :to="{ name: 'legalRU', query: {legal_id: 2} }" class="underline lg:text-[26px] md:text-[20px] text-[18px] mb-[40px]">
          Политика конфиденциальности при использовании мобильного приложения trafficGram
        </router-link>
        <router-link :to="{ name: 'legalRU', query: {legal_id: 3} }" class="underline lg:text-[26px] md:text-[20px] text-[18px] mb-[40px]">
          Публичное соглашение об использовании базы данных
        </router-link>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
import Footer from "@/components/layouts/Footer.vue"

export default {
  components: {
    Footer
  },
  data(){
    return {

    }
  },
  mounted(){
    window.scrollTo({ top: 0 });
  },
}
</script>

<style scoped>
ul li {
  margin-top: 20px
}
li {
  line-height: 24px;
}
@media (min-width: 1024px) {
  ul li {
  margin-top: 30px
}
}
</style>