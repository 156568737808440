<template>
  <div
    :class="`w-full ${
      isVisiable ? 'block' : 'hidden'
    } z-[999] py-3 flex items-center justify-between bg-white`"
  >
    <div @click="close()" class="flex items-center">
      <div class="flex items-center p-[10px] pr-0">
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
          <path
            d="m18.1 8.1-2.2-2.2-3.9 4-3.9-4-2.2 2.2 4 3.9-4 3.9 2.2 2.2 3.9-4 3.9 4 2.2-2.2-4-3.9 4-3.9Z"
            fill="#000"
          ></path>
        </svg>
      </div>
      <div class="flex items-center p-3">
        <img
          class="w-[75px]"
          :src="this.url == 'driver' ? trafficGram : logoGram"
          alt=""
        />
      </div>
      <div class="flex flex-col">
        <h5 class="font-medium text-[12px]">
          Скачайте приложение
          {{ this.url == "driver" ? "Traffic Gram" : "Gram" }}
        </h5>
        <p class="text-[10px]">
          {{
            this.url == "driver"
              ? "Используйте приложение для поиска клиентов и заработка."
              : "Используйте приложение для заказа поездок или доставки товаров."
          }}
        </p>
      </div>
    </div>
    <div class="p-[10px] pr-[15px]">
      <a
        target="_blank"
        :href="this.url == 'driver' ? 'https://gram.tj/driver/app' : appLink"
      >
        <button
          class="border-none text-[14px] leading-[20px] font-medium rounded-[10px] hover:opacity-80 duration-200 px-[13px] py-[7px] bg-black text-white"
        >
          Скачать
        </button>
      </a>
    </div>
  </div>
</template>

<script>
import checkPlatform from "@/utils/downloadLink.js";
export default {
  data() {
    return {
      trafficGram: require("@/assets/img/traffic icon.svg"),
      logoGram: require("@/assets/img/logo.svg"),
      isVisiable: true,
      appLink: null,
      url: null,
    };
  },
  mounted() {
    this.appLink = checkPlatform();
  },
  methods: {
    close() {
      this.isVisiable = !this.isVisiable;
    },
  },
  watch: {
    $route(to) {
      this.url = to.fullPath.replace(/.*\//, "");
    },
  },
};
</script>
